import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import './Dashboard.css'

const Dashboard = () => {
  
  return (
    <Container className="p-8">
      <h1 className="fs-3 font-bold mb-4">E1 DocEx - Workday Document Management</h1>
      
      <Row className="mt-8 font-custom">
        <Col md={3} className="hover-col">
          <Card className="p-1 rounded-lg shadow-sm card-body">
            <Card.Body className="flex gap-3 items-center">
              <Card.Subtitle>Documents Downloaded</Card.Subtitle>
              <Card.Text className="text-4xl font-bold">3</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="hover-col">
          <Card className="p-1 rounded-lg shadow-sm card-body">
            <Card.Body className="flex gap-3 items-center ">
              <Card.Subtitle>Documents Uploaded</Card.Subtitle>
              <Card.Text className="text-4xl font-bold">5</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="hover-col">
          <Card className="p-1 rounded-lg shadow-sm card-body">
            <Card.Body className="flex gap-2 items-center ">
              <Card.Subtitle>Total Users</Card.Subtitle>
              <Card.Text className="text-4xl font-bold">1</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="hover-col">
          <Card className="p-1 rounded-lg shadow-sm card-body">
            <Card.Body className="flex gap-2 items-center ">
              <Card.Subtitle>Tenants Configured</Card.Subtitle>
              <Card.Text className="text-4xl font-bold">2</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
