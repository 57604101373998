import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Spinner, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import api from "../api/api";
import DocumentCategoryOption from "../Components/DocumnetCategoryOption";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const UploadDocument = () => {
  const { handleSubmit } = useForm();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const options = DocumentCategoryOption().map((option) => ({
    value: option.value,
    label: option.label,
  }));
  const [rowData, setRowData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const fileInputRefs = useRef({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tenantOption, setTenantOption] = useState([]);
  const [selectedTenantOptions, setSelectedTenantOptions] = useState([]);
  const [isUploadClicked, setIsUploadClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/allemployee");
        const sortedOptions = response.data.data
          .map((employee) => ({
            value: employee.employeeID,
            label: employee.nameInGeneralDisplayFormat,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setEmployeeOptions(sortedOptions);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    }

    async function fetchData2() {
      try {
        const response = await api.get("/tenant/uploaddocument");
        const sortedOptions = response.data.tenants.map((tenant) => ({
          value: tenant.id,
          label: tenant.tenant_name,
        }));
        setTenantOption(sortedOptions);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    fetchData2();
  }, []);

  const validateFields = () => {
    let newErrors = {};
    rowData.forEach((row) => {
      if (!row.employeeIds || row.employeeIds.length === 0) {
        newErrors[row.id] = {
          ...newErrors[row.id],
          employeeIds: "Employee is required",
        };
      }
      if (!row.documentCategory) {
        newErrors[row.id] = {
          ...newErrors[row.id],
          documentCategory: "Document Category is required",
        };
      }
      if (row.files.length === 0) {
        newErrors[row.id] = {
          ...newErrors[row.id],
          files: "File is required",
        };
      }
      if (row.employeeIds.length > 1 && row.files.length > 1) {
        newErrors[row.id] = {
          ...newErrors[row.id],
          files: "Multiple employees can only have a single file uploaded.",
        };
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addRow = () => {
    setShowTable(true);
    setRowData((prevRowData) => [
      ...prevRowData,
      { id: Date.now(), employeeIds: [], files: [], documentCategory: null },
    ]);
  };

  const onDeleteRow = (id) => {
    setRowData(rowData.filter((row) => row.id !== id));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };

  const handleInitialSelectChange = (selectedOptions) => {
    setSelectedTenantOptions(selectedOptions); // Update state for multi-select options
  };

  const handleFileChange = (e, id) => {
    const files = Array.from(e.target.files);
    const row = rowData.find((row) => row.id === id);

    setRowData((prevRowData) =>
      prevRowData.map((row) => (row.id === id ? { ...row, files } : row))
    );

    if (row.employeeIds.length > 1 && files.length > 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: {
          ...prevErrors[id],
          files: "Multiple employees can only have a single file uploaded.",
        },
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (newErrors[id]) {
          delete newErrors[id].files;
          if (Object.keys(newErrors[id]).length === 0) {
            delete newErrors[id];
          }
        }
        return newErrors;
      });
    }
  };

  const onSubmit = async () => {
    if (!validateFields()) {
      Swal.fire(
        "Validation Error",
        "Please fill in all required fields.",
        "error"
      );
      return;
    }

    const result = await Swal.fire({
      icon: "question",
      title: "Confirm Upload",
      text: "Are you sure you want to upload the selected document(s)?",
      showCancelButton: true,
      confirmButtonText: "Upload",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setLoading(true);

      const tenantIds = selectedTenantOptions.map((option) => option.value);
      const uploadPromises = rowData.map(async (row) => {
        const uploadFilesPromises = row.files.map((file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async () => {
              const base64File = fileReader.result.split(",")[1];
              const requestBody = {
                tenant_id: tenantIds,
                fileName: file.name,
                employee_name: employeeOptions.find(
                  (option) => option.value === row.employeeIds[0]
                ).label,
                employee_id: Array.isArray(row.employeeIds)
                  ? row.employeeIds
                  : [row.employeeIds],
                document_category_name: options.find(
                  (option) => option.value === row.documentCategory
                ).label,
                document_category_id: row.documentCategory,
                documents: [
                  {
                    fileName: file.name,
                    fileContentBase64: base64File,
                  },
                ],
              };

              try {
                const response = await api.post(
                  "/uploaddocuments",
                  requestBody
                );
                resolve(response.data);
              } catch (error) {
                reject(error);
              }
            };

            fileReader.onerror = () => {
              reject("Error reading file");
            };

            fileReader.readAsDataURL(file);
          });
        });

        return Promise.all(uploadFilesPromises);
      });

      try {
        const responses = await Promise.all(uploadPromises);
        const flattenedResponses = responses.flat();
        let allSuccess = true;

        flattenedResponses.forEach((response) => {
          if (response.status !== "success") {
            allSuccess = false;
          }
        });

        if (allSuccess) {
          Swal.fire({
            icon: "success",
            title: "Uploading",
            text: "File's are uploading",
          }).then(() => {
            navigate("/monitor");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Upload Not Completed",
            text: "Some files failed to upload.",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire("Error", "An error occurred during upload", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  // Check if any tenant is selected
  const isTenantSelected = selectedTenantOptions.length > 0;

  return (
    <Container>
      <h3 className="fs-2 mb-4 font-bold">Upload Documents</h3>
      <Row>
        <label>Choose Tenant</label>
        <Col lg="6">
          <Select
            className="react-select-container mt-3 mb-4"
            options={tenantOption}
            onChange={handleInitialSelectChange}
            isMulti
            value={selectedTenantOptions}
          />
        </Col>
      </Row>
      {isTenantSelected && (
        <Button
          variant="warning"
          onClick={() => {
            addRow();
            setIsUploadClicked(true);
          }}
          className="mb-4 button-default"
        >
          {isUploadClicked ? "Add Rows" : "Upload"}
        </Button>
      )}

      {showTable && (
        <div className="mb-4">
          <table className="table" border={1}>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Document Category</th>
                <th>Files</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rowData.map((row) => (
                <tr key={row.id}>
                  <td>
                    <Select
                      options={employeeOptions}
                      isMulti
                      value={employeeOptions.filter((option) =>
                        row.employeeIds.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const employeeIds = selectedOptions.map(
                          (option) => option.value
                        );
                        setRowData((prevRowData) =>
                          prevRowData.map((prevRow) =>
                            prevRow.id === row.id
                              ? { ...prevRow, employeeIds }
                              : prevRow
                          )
                        );
                        setErrors((prevErrors) => {
                          const newErrors = { ...prevErrors };
                          if (newErrors[row.id]) {
                            delete newErrors[row.id].employeeIds;
                            if (Object.keys(newErrors[row.id]).length === 0) {
                              delete newErrors[row.id];
                            }
                          }
                          return newErrors;
                        });
                      }}
                    />
                    {errors[row.id]?.employeeIds && (
                      <div style={{ color: "red" }}>
                        {errors[row.id].employeeIds}
                      </div>
                    )}
                  </td>
                  <td>
                    <Select
                      options={options}
                      value={options.find(
                        (option) => option.value === row.documentCategory
                      )}
                      onChange={(selectedOption) => {
                        setRowData((prevRowData) =>
                          prevRowData.map((prevRow) =>
                            prevRow.id === row.id
                              ? {
                                  ...prevRow,
                                  documentCategory: selectedOption.value,
                                }
                              : prevRow
                          )
                        );
                        setErrors((prevErrors) => {
                          const newErrors = { ...prevErrors };
                          if (newErrors[row.id]) {
                            delete newErrors[row.id].documentCategory;
                            if (Object.keys(newErrors[row.id]).length === 0) {
                              delete newErrors[row.id];
                            }
                          }
                          return newErrors;
                        });
                      }}
                    />
                    {errors[row.id]?.documentCategory && (
                      <div style={{ color: "red" }}>
                        {errors[row.id].documentCategory}
                      </div>
                    )}
                  </td>
                  <td>
                    <div>
                      {row.files.length > 0
                        ? row.files.map((file) => file.name).join(", ")
                        : "No files chosen"}
                      <input
                        type="file"
                        multiple
                        onChange={(e) => handleFileChange(e, row.id)}
                        style={{ display: "none" }}
                        ref={(el) => (fileInputRefs.current[row.id] = el)}
                      />
                      <Button
                        variant="warning"
                        onClick={() => fileInputRefs.current[row.id].click()}
                        className="button-default"
                      >
                        Upload
                      </Button>
                      {errors[row.id]?.files && (
                        <div style={{ color: "red" }}>
                          {errors[row.id].files}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => onDeleteRow(row.id)}
                      className="button-default"
                      disabled={rowData.length <= 1}
                    >
                      <AiOutlineDelete />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isTenantSelected && (
            <Button
              variant="warning"
              className="mb-4 button-default"
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </div>
      )}
    </Container>
  );
};

export default UploadDocument;
