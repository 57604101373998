import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'


const Upload = () => {
    const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles?.length) {
        setFiles(previousFiles => [
            ...previousFiles,
            acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}))
        ])
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept : {'pdf/*' : []}, maxSize: 1024 * 1000})
    return (
        <>
        <form>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop Files Here ...</p>
                ) : (
                    <p>Drag and Drop some files here or click to select files*-</p>
                )}
            </div>
        </form>
    </>
    )
}

export default Upload