import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import api from "../api/api";
import JSZip from "jszip";
import Swal from "sweetalert2";
import { Form, Button, Row, Col, Alert, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import DocumnetCategoryOption from "../Components/DocumnetCategoryOption";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./radio.css";

const ExtractDocuments = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [employeeIds, setEmployeeIds] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTenantOption, setSelectedTenantOption] = useState(null); 
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [tenantOption, setTenantOption] = useState([]);
  const [extractLoading, setExtractLoading] = useState(false); 

  const options = DocumnetCategoryOption().sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setEmployeeIds([]);
    setDocumentCategories([]);
    setShowGrid(false); // Hide the grid when changing options
  };

  const handleEmployeeIdChange = (selectedOption) => {
    setEmployeeIds(selectedOption.map((option) => option.value));
  };

  const handleDocumentCategoryChange = (selectedOption) => {
    setDocumentCategories(selectedOption.map((option) => option.value));
  };

  const handleInitialSelectChange = (selectedOption) => {
    setSelectedTenantOption(selectedOption);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await api.get("/allemployee");
      const sortedOptions = response.data.data
        .map((employee) => ({
          value: employee.employeeID,
          label: employee.nameInGeneralDisplayFormat,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setEmployeeOptions(sortedOptions);
    }

    async function fetchData2() {
      try {
        const response = await api.get("/tenant/extractdocument");
        const sortedOptions = response.data.tenants.map((tenant) => ({
          value: tenant.id,
          label: tenant.tenant_name,
        }));
        setTenantOption(sortedOptions);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
    fetchData2();
  }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const tenantIds = selectedTenantOption.value;
      const response = await api.post(`/getdocumentlist`, {
        tenant_id: tenantIds,
        employeeids: employeeIds,
      });

      if (response.data.length === 0) {
        setShowGrid(false);
        const employeeNames = employeeIds.map((id) => {
          const selectedEmployee = employeeOptions.find(
            (option) => option.value === id
          );
          return selectedEmployee ? selectedEmployee.label : id;
        });
        setErrorMessage(
          `No files available for ${
            employeeNames.length > 1 ? "employees" : "employee"
          } ${employeeNames.join(", ")}`
        );
      } else {
        const transformedData = response.data.flatMap((item) =>
          item.documents.map((doc) => ({ ...doc, worker: item.worker }))
        );
        let filteredData = transformedData;
        if (documentCategories.length > 0) {
          filteredData = filteredData.filter((item) =>
            documentCategories.includes(item.document_category_id)
          );
          if (filteredData.length === 0) {
            setShowGrid(false);
            setErrorMessage(`No files available for the selected categories.`);
            return;
          }
        }
        setRowData(filteredData);
        setShowGrid(true);
        setErrorMessage("");
      }
    } catch (error) {
      console.error(error);
      setShowGrid(false);
      setErrorMessage("Error fetching data. Please try again.");
    }
  };

  const downloadFile = async (doc) => {
    const link = document.createElement("a");
    link.href = `data:application/octet-stream;base64,${doc.base64content}`;
    link.download = doc.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    Swal.fire({
      icon: "success",
      title: "Download Complete",
      text: "Selected documents have been downloaded successfully as a pdf file.",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedRows([]);
        gridApi.deselectAll();
      }
    });
  };

  const downloadZip = async () => {
    const zip = new JSZip();

    selectedRows.forEach((doc) => {
      zip.file(doc.filename, doc.base64content, { base64: true });
    });

    const content = await zip.generateAsync({ type: "blob" });
    const workerName = selectedRows[0].worker.name.replace(/\s+/g, "_");
    const workerId = selectedRows[0].worker.employee_id;
    // const zipFileName = `${workerName}_${workerId}_documents.zip`;
    const zipFileName = `documents.zip`;
    const url = window.URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = url;
    link.download = zipFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    Swal.fire({
      icon: "success",
      title: "Download Complete",
      text: "Selected documents have been downloaded successfully as a zip file.",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedRows([]);
        gridApi.deselectAll();
      }
    });
  };

  const confirmDownload = () => {
    Swal.fire({
      icon: "question",
      title: "Confirm Download",
      text: "Are you sure you want to download the selected document(s)?",
      showCancelButton: true,
      confirmButtonText: "Download",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setExtractLoading(true);
        setTimeout(() => {
          setExtractLoading(false);
          if (selectedRows.length === 1) {
            downloadFile(selectedRows[0]);
          } else if (selectedRows.length > 1) {
            downloadZip();
          }
        }, 2000);
      }
    });
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
    },
    { headerName: "Employee ID", field: "worker.employee_id", flex: 1 },
    { headerName: "Name", field: "worker.name", flex: 1 },
    { headerName: "Filename", field: "filename", flex: 1 },
    { headerName: "Document Category", field: "document_category_id", flex: 1 },
  ];

  const buttonStyle = {
    backgroundColor: "#f2a922",
    width: "25%",
    border: "none",
    cursor: "pointer",
    padding: "10px",
    fontSize: "16px",
    color: "#1d1932",
    transition: "background-color 0.3s ease",
  };

  return (
    <div>
      <div>
        <h3 className="fs-2 mb-4 font-bold">Extract Documents</h3>
      </div>
      <Row>
        <label>Choose Tenant</label>
        <Col lg="6">
          <Select
            className="react-select-container mt-3 mb-4"
            options={tenantOption}
            onChange={handleInitialSelectChange}
          />
        </Col>
      </Row>

      {selectedTenantOption && (
        <div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>
                <Form.Check
                  inline
                  type="radio"
                  label="Employee Id"
                  value="employee"
                  checked={selectedOption === "employee"}
                  onChange={handleOptionChange}
                  style={{ marginRight: "10px" }}
                />
              </Col>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>
                <Form.Check
                  inline
                  type="radio"
                  label="Document Category"
                  value="document"
                  checked={selectedOption === "document"}
                  onChange={handleOptionChange}
                  style={{ marginRight: "10px" }}
                />
              </Col>
            </Row>

            {selectedOption === "employee" && (
              <div className="extract-form">
                <Row className="align-items-center">
                  <Col lg="6">
                    <label htmlFor="employeeid">Employee ID</label>
                    <Select
                      {...register("employeeIds")}
                      className="react-select-container mt-3 mb-4"
                      options={employeeOptions}
                      isMulti
                      onChange={handleEmployeeIdChange}
                    />
                    &nbsp;
                    <button
                      type="submit"
                      className="upload-btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </Col>
                </Row>
              </div>
            )}

            {selectedOption === "document" && (
              <div className="extract-form">
                <Row className="align-items-center">
                  <Col lg="6">
                    <label htmlFor="documentCategory">Document Category</label>
                    <Select
                      {...register("documentCategories")}
                      className="react-select-container mt-3 mb-4"
                      options={options}
                      isMulti
                      onChange={handleDocumentCategoryChange}
                    />
                  </Col>
                  {documentCategories.length > 0 && (
                  <Col lg="6">
                    <label htmlFor="employeeid">Employee ID</label>
                    <Select
                      {...register("employeeIds")}
                      className="react-select-container mt-3 mb-4"
                      options={employeeOptions}
                      isMulti
                      onChange={handleEmployeeIdChange}
                    />
                  </Col>
                )}
                </Row>
                
                &nbsp;
                <button type="submit" className="upload-btn" disabled={loading}>
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </Form>

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

          {showGrid && (
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                rowSelection="multiple"
                paginationPageSize={10}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onGridReady}
              />
              <hr />
              <Button
                className="upload-btn my-3"
                onClick={confirmDownload}
                disabled={selectedRows.length === 0}
                style={buttonStyle}
              >
                {extractLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Extract"
                )}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExtractDocuments;
