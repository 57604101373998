import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";
import api from "../../api/api";
import "../../Pages/UserManagement.css";

const AddUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [passport, setPassport] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [existingEmails, setExistingEmails] = useState([]); 
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get('/get_all_user');
        const users = response.data.users;
        const emails = users.map(user => user.email);
        setExistingEmails(emails);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const validateFields = () => {
    let newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (existingEmails.includes(email)) newErrors.email = "Email is already taken";
    if (!passport) newErrors.passport = "Password is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); 
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", passport);

      try {
        const postData = await api.post("/user", formData);
        console.log(postData);

        Swal.fire({
          title: "User Created!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/usermanagement");
        });
      } catch (error) {
        console.error("Error creating user:", error);
        Swal.fire({
          title: "Error!",
          text: "There was an issue creating the user.",
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <Container>
      <h3 className="fs-2 mb-4 font-bold">Add User</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg="6">
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors({ ...errors, name: "" });
                }}
                maxLength={30}
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, email: "" });
                }}
                maxLength={50}
              />
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="position-relative" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={passport}
                onChange={(e) => {
                  setPassport(e.target.value);
                  setErrors({ ...errors, passport: "" });
                }}
                maxLength={30}
              />
              <div
                className="position-absolute top-50 end-0 translate-middle-y mt-3 me-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </div>
              {errors.passport && (
                <div className="text-danger">{errors.passport}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          className="mt-3 add-user"
          disabled={loading} 
        >
          {loading ? (
            <Spinner animation="border" size="sm" /> 
          ) : (
            "Submit"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default AddUser;
