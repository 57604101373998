import React from 'react';

const DocumnetCategoryOption = () => {
    return [
        { value: "ID_Verification", label: "ID Verification" },
        { value: "OTHER", label: "Other" },
        { value: "Driving_License", label: "Driving License" },
        { value: "STUDENT_COLLECTIONS", label: "Student Collections" },
        { value: "PROSPECT_RESUME_AND_COVER_LETTER", label: "Prospect Resume and Cover Letter" },
        { value: "CANDIDATE_RESUME_AND_COVER_LETTER", label: "Candidate Resume and Cover Letter" },
        { value: "RESIDENCY_DETERMINATION", label: "Residency Determination (Student)" },
        { value: "EDUCATION", label: "Education" },
        { value: "MULTI_MEDIA_AUDIO", label: "Multi-Media (Audio) (Student)" },
        { value: "MULTI_MEDIA_VIDEO", label: "Multi-Media (Video) (Student)" },
        { value: "DRIVEDOCUMENTTEMPLATE", label: "Drive Document Template" },
        { value: "COMMITTEES", label: "Committees" },
        { value: "RECOMMENDATION", label: "Recommendation (Student)" },
        { value: "POSITION", label: "Position" },
        { value: "CREDENTIAL", label: "Credential (Student)" },
        { value: "RETIREE", label: "Retiree" },
        { value: "APPLICATION", label: "Application (Student)" },
        { value: "SEC", label: "Secondary" },
        { value: "ACTION_ITEM", label: "Action Item (Student)" },
        { value: "REFERENCE_LETTER", label: "Reference Letter" },
        { value: "ACCOMMODATION_VERIFICATION", label: "Accommodation Verification (Student)" },
        { value: "PROBATION_PERIOD", label: "Probation Period" },
        { value: "LANGUAGE_PROFICIENCY", label: "Language Proficiency (Student)" },
        { value: "TIME_OFF", label: "Time Off" },
        { value: "PORTFOLIO", label: "Portfolio (Student)" },
        { value: "RESUME", label: "Resume (Student)" },
        { value: "EMPLOYEE_CONTRACT", label: "Employee Contract" },
        { value: "WRITING_SAMPLE", label: "Writing Sample (Student)" },
        { value: "BENEFITS", label: "Benefits" },
        { value: "VERIFICATION", label: "Verification (Student)" },
        { value: "CERT", label: "Certification" },
        { value: "COMPANY_POLICY_RELATED", label: "Company Policy Related" },
        { value: "LOA", label: "Leave of Absence" },
        { value: "PERSONAL_INFORMATION", label: "Personal Information" },
        { value: "TIME_TRACKING", label: "Time Tracking" },
        { value: "OFFERS", label: "Offers" },
        { value: "BACKGROUND CHECK", label: "Background Check" },
        { value: "ASSESSMENT", label: "Assessment" },
        { value: "INTERVIEW", label: "Interview" },
        { value: "OTHER_DOCUMENTS", label: "Other Documents" },
        { value: "AGENCY", label: "Agency" },
        { value: "ACADEMIC_APPOINTMENTS", label: "Academic Appointments" },
        { value: "PERIOD_ACTIVITY_PAY", label: "Period Activity Pay" },
        { value: "NAMED_PROFESSORSHIPS", label: "Named Professorships" },
        { value: "NOTICE_PERIOD", label: "Notice Period" },
        { value: "LICENSES", label: "Licenses" },
        { value: "PASSPORTS_AND_VISAS", label: "Passports and Visas" },
        { value: "DEPENDENTS", label: "Dependents" },
        { value: "EMPLOYMENT_AGREEMENT", label: "Employment Agreement" },
        { value: "OTHER_STUDENT", label: "Other (Student)" },
        { value: "INTERNATIONAL_STUDENT", label: "International Student" },
        { value: "Tax_Forms", label: "Tax Forms" },
        { value: "Address_Verification", label: "Address Verification" },
        { value: "Compensation", label: "Compensation" },
        { value: "Contingent_Worker_Contract", label: "Contingent Worker Contract" },
        { value: "Education_Reimbursement", label: "Education Reimbursement" },
        { value: "Employment_Eligibility", label: "Employment Eligibility" },
        { value: "Flexible_Work_Arrangement", label: "Flexible Work Arrangement" },
        { value: "HIRE", label: "Hire" },
        { value: "Legal_Name_Change", label: "Legal Name Change" },
        { value: "LICENSE", label: "License" },
        { value: "Marital_Status_Change", label: "Marital Status Change" },
        { value: "Meal_Waiver", label: "Meal Waiver" },
        { value: "Non_Disclosure_Agreement", label: "Non-Disclosure Agreement" },
        { value: "Offer_Letter", label: "Offer Letter" },
        { value: "Personal_Information_Verification", label: "Personal Information Verification " },
        { value: "PROMOTION", label: "Promotion" },
        { value: "Termination", label: "Termination" },
        { value: "TRANSFER", label: "Transfer" },
      ];
}

export default DocumnetCategoryOption
