import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { MdEdit, MdDelete } from 'react-icons/md'; // Importing MdEdit and MdDelete icons
import api from '../api/api';
import Swal from 'sweetalert2';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get('/get_all_user');
        console.log(response.data);
        setUsers(response.data.users);
      } catch(error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleAddUserClick = () => {
    navigate('/add-user');
  }

  const renderActionButtons = (params) => {
    const handleEditClick = () => {
      console.log('Edit clicked for:', params.data);
      const userId = params.data.id; // Assuming id is the unique identifier
      navigate(`/edit-user/${userId}`);

    };

  
    const handleDeleteClick = async () => {
      Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                  await api.delete(`/user/${params.data.id}`);
                  Swal.fire(
                      'Deleted!',
                      'The user has been deleted.',
                      'success'
                  );
                  // Optionally, refresh the list of users or update the state
              } catch (error) {
                  console.error('Error deleting user:', error);
                  Swal.fire(
                      'Error!',
                      'There was a problem deleting the user.',
                      'error'
                  );
              }
          }
      });
  };


    return (
      <div>
        <Button variant="link" size="sm" className="text-decoration-none me-2" onClick={() => handleEditClick(params)}>
          <MdEdit className="text-primary" style={{ width: 20, height: 20 }} /> 
        </Button>
        <Button variant="link" size="sm" className="text-decoration-none" onClick={() => handleDeleteClick(params)}>
          <MdDelete className="text-danger" style={{ width: 20, height: 20 }}/> 
        </Button>
      </div>
    );
  };

  const columnDefs = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true, flex: 1 },
    { headerName: 'Name', field: 'name', sortable: true, filter: true, flex: 1 },
    { headerName: 'Email', field: 'email', sortable: true, filter: true, flex: 1 },
    { headerName: 'Action', cellRenderer: renderActionButtons, sortable: false, filter: false, flex: 1 },
  ];

  return (
    <div>
      <div className='d-flex justify-content-between mb-3'>
        <h3 className="fs-2 font-bold">User Management</h3>
        <Button className='add-user' onClick={handleAddUserClick}>Add User</Button>
      </div>
      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          rowData={users}
          columnDefs={columnDefs}
          domLayout='autoHeight'
          pagination={true}
          paginationPageSize={10}
        />
      </div> 
    </div>
  );
}

export default UserManagement;
