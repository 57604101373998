import React, { useState, useEffect } from "react";
import useAuthContext from "../context/AuthContext";
import api from "../api/api";
import { AgGridReact } from "ag-grid-react";
import { VscPass, VscError } from "react-icons/vsc";
import { GrUploadOption } from "react-icons/gr";
import { Button, Spinner, Modal } from "react-bootstrap";
import "./Monitor.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const Monitor = () => {
  const { user, getUser } = useAuthContext();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (user) {
      console.log("Checking user id:", user.id);
      fetchData();
    } else {
      getUser();
    }
  }, [user, getUser]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [user]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/statuslog/${user.id}`);
      setRowData(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleManualRefresh = () => {
    fetchData();
  };

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const columnDefs = [
    { headerName: "Employee", field: "employee_name", flex: 1 },
    { headerName: "Document Category", field: "document_category_name", flex: 1 },
    { headerName: "Filename", field: "filename", flex: 1 },
    { headerName: "Tenant Name", field: "tenant_name", flex: 1 },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      cellRenderer: (params) => {
        const status = params.value;
        if (status === "pending") {
          return <GrUploadOption style={{ fontSize: "24px", color: "#FFDE00" }} />;
        } else if (status === "success") {
          return <VscPass style={{ fontSize: "24px", color: "#28a745" }} />;
        } else {
          return <VscError style={{ fontSize: "24px", color: "#dc3545" }} />;
        }
      },
    },
    { headerName: "Message", field: "message", flex: 1 },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      cellRenderer: (params) => (
        <Button size="sm" className="view-btn text-decoration-none me-2" onClick={() => handleViewClick(params.data)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="monitor-container">
      <div className="d-flex justify-content-between">
        <h3 className="fs-2 font-bold">Document Monitor</h3>
        <Button
          className="refresh-btn mb-3"
          onClick={handleManualRefresh}
          disabled={loading}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Refresh Grid"
          )}
        </Button>
      </div>
      <div className="ag-theme-alpine monitor-grid">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={10}
          rowHeight={40}
          domLayout="autoHeight"
          suppressRowClickSelection={true}
          suppressCellSelection={true}
          enableCellTextSelection={false}
          rowSelection={"multiple"}
        />
      </div>

      {/* Modal to show selected row data */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Row Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow ? (
            <div>
              <p><strong>Employee:</strong> {selectedRow.employee_name}</p>
              <p><strong>Document Category:</strong> {selectedRow.document_category_name}</p>
              <p><strong>Filename:</strong> {selectedRow.filename}</p>
              <p><strong>Tenant Name:</strong> {selectedRow.tenant_name}</p>
              <p><strong>Status:</strong> {selectedRow.status}</p>
              <p><strong>Message:</strong> {selectedRow.message}</p>
            </div>
          ) : (
            <p>No data to display</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Monitor;
