import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../api/api";
import { FiEye, FiEyeOff } from "react-icons/fi";

const EditTenant = () => {
  const { TenantId } = useParams();
  const navigate = useNavigate();

  const [tenant, setTenant] = useState({
    tenant_name: "",
    tenant_url: "",
    tenant_username: "",
    tenant_password: "",
    tenant_alias: "",
    tenant_tag: "",
    staffing_url: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const tenantTagOptions = [
    { value: "soap_call", label: "SOAP Endpoint" },
    { value: "raas_call", label: "RAAS Endpoint" },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/tenant/get/${TenantId}`);
        setTenant(response.data.tenant);
      } catch (error) {
        console.error(error);
        Swal.fire("Error!", "Failed to fetch tenant data.", "error");
      }
    }
    fetchData();
  }, [TenantId]);

  const validateFields = () => {
    let newErrors = {};
    if (!tenant.tenant_name) newErrors.tenant_name = "Tenant Name is required";
    if (!tenant.tenant_url) newErrors.tenant_url = "Tenant URL is required";
    if (!tenant.tenant_username)
      newErrors.tenant_username = "Username is required";
    if (!tenant.tenant_password)
      newErrors.tenant_password = "Password is required";
    if (!tenant.tenant_alias)
      newErrors.tenant_alias = "Tenant Alias is required";
    if (tenant.tenant_tag === "raas_call" && !tenant.staffing_url) {
      newErrors.staffing_url = "Staffing URL is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        const response = await api.put(`/tenant/edit/${TenantId}`, tenant);
        console.log(response.data);
        Swal.fire("Success!", "Tenant updated successfully.", "success").then(
          () => {
            navigate("/tenantmanagement");
          }
        );
      } catch (error) {
        console.error("Error updating tenant:", error);
        Swal.fire(
          "Error!",
          "There was a problem updating the tenant.",
          "error"
        );
      } finally {
        setLoading(false); 
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenant({ ...tenant, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSelectChange = (selectedOption) => {
    setTenant({
      ...tenant,
      tenant_tag: selectedOption ? selectedOption.value : "",
    });
    setErrors({ ...errors, tenant_tag: "" });
  };

  return (
    <Container className="my-3">
      <h3 className="fs-2 mb-4 font-bold">Edit Tenant</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg="6">
            <Form.Group className="mb-3" controlId="tenantName">
              <Form.Label>Tenant Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tenant name"
                name="tenant_name"
                value={tenant.tenant_name}
                onChange={handleChange}
                maxLength={30}
              />
              {errors.tenant_name && (
                <div className="text-danger">{errors.tenant_name}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3" controlId="tenantURL">
              <Form.Label>Tenant URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tenant URL"
                name="tenant_url"
                value={tenant.tenant_url}
                onChange={handleChange}
                maxLength={250}
              />
              {errors.tenant_url && (
                <div className="text-danger">{errors.tenant_url}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3" controlId="tenantUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                name="tenant_username"
                value={tenant.tenant_username}
                onChange={handleChange}
                maxLength={30}
              />
              {errors.tenant_username && (
                <div className="text-danger">{errors.tenant_username}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3 position-relative" controlId="tenantPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                name="tenant_password"
                value={tenant.tenant_password}
                onChange={handleChange}
                maxLength={30}
              />
              <div
                className="position-absolute top-50 end-0 translate-middle-y mt-3 me-2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </div>
              {errors.tenant_password && (
                <div className="text-danger">{errors.tenant_password}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3" controlId="tenantAlias">
              <Form.Label>Alias</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter alias"
                name="tenant_alias"
                value={tenant.tenant_alias}
                onChange={handleChange}
                maxLength={30}
              />
              {errors.tenant_alias && (
                <div className="text-danger">{errors.tenant_alias}</div>
              )}
            </Form.Group>
          </Col>
          <Col lg="6">
            <Form.Group className="mb-3" controlId="tenantTag">
              <Form.Label>Tenant Tag</Form.Label>
              <Select
                options={tenantTagOptions}
                value={tenantTagOptions.find(
                  (option) => option.value === tenant.tenant_tag
                )}
                onChange={handleSelectChange}
                placeholder="Select tenant tag"
              />
              {errors.tenant_tag && (
                <div className="text-danger">{errors.tenant_tag}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        {tenant.tenant_tag === "raas_call" && (
          <Form.Group className="mb-3" controlId="staffingURL">
            <Form.Label>Staffing URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter staffing URL"
              name="staffing_url"
              value={tenant.staffing_url}
              onChange={handleChange}
              maxLength={100}
            />
            {errors.staffing_url && (
              <div className="text-danger">{errors.staffing_url}</div>
            )}
          </Form.Group>
        )}
        <Button
          variant="primary"
          type="submit"
          className="add-user"
          disabled={loading}
        >
          {loading ? (
            <Spinner animation="border" size="sm" /> 
          ) : (
            "Submit"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default EditTenant;
