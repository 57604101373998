import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./styles.css";
import { MdEdit, MdDelete } from "react-icons/md";
import api from "../api/api";
import Swal from "sweetalert2";

function Tenant() {
  const navigate = useNavigate();
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/tenant/get");
        console.log(response.data.tenants);
        setTenants(response.data.tenants);
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    }

    fetchData();
  }, []);

  const handleAddTenantClick = () => {
    navigate("/add-tenant");
  };

  const renderActionButtons = (params) => {
    const handleEditClick = () => {
      console.log("Edit clicked for:", params.data);
      const TenantId = params.data.id;
      navigate(`/edit-tenant/${TenantId}`);
    };

    const handleDeleteClick = async (params) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await api.delete(`/tenant/delete/${params.data.id}`);
            Swal.fire("Deleted!", "The Tenant has been deleted.", "success");

            setTenants((prevTenants) =>
              prevTenants.filter((tenant) => tenant.id !== params.data.id)
            );
          } catch (error) {
            console.error("Error deleting tenant:", error);
            Swal.fire(
              "Error!",
              "There was a problem deleting the tenant.",
              "error"
            );
          }
        }
      });
    };

    return (
      <div>
        <Button
          variant="link"
          size="sm"
          className="text-decoration-none me-2"
          onClick={() => handleEditClick(params)}
        >
          <MdEdit className="text-primary" style={{ width: 20, height: 20 }} />
        </Button>
        <Button
          variant="link"
          size="sm"
          className="text-decoration-none"
          onClick={() => handleDeleteClick(params)}
        >
          <MdDelete className="text-danger" style={{ width: 20, height: 20 }} />
        </Button>
      </div>
    );
  };

  const columnDefs = [
    { headerName: "ID", field: "id", sortable: true, filter: true, flex: 1 },
    {
      headerName: "Tenant Name",
      field: "tenant_name",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Tenant URL",
      field: "tenant_url",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Username",
      field: "tenant_username",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Password",
      field: "tenant_password",
      sortable: true,
      filter: true,
      hide:true,
      flex: 1,
    },
    {
      headerName: "Alias",
      field: "tenant_alias",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Tag",
      field: "tenant_tag",
      sortable: true,
      filter: true,
      flex: 1,
    },
    {
      headerName: "Action",
      cellRenderer: renderActionButtons,
      sortable: false,
      filter: false,
      flex: 1,
    },
  ];

  return (
    <div>
      <Container className="my-3">
        <Row>
          <Col lg="6">
            <h3 className="fs-2 mb-4 font-bold">Tenant Management</h3>
          </Col>
          <Col lg="6">
            <Button
              variant="primary add-tenant"
              onClick={handleAddTenantClick}
              style={{ float: "right" }}
              className="add-tnt"
            >
              Add Tenant
            </Button>
          </Col>
        </Row>
      </Container>
      <div
        className="ag-theme-alpine"
        style={{ height: "400px", width: "100%" }}
      >
        <AgGridReact
          rowData={tenants}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={10}
        />
      </div>
    </div>
  );
}

export default Tenant;
