import React, { useState, useEffect } from "react";
import api from "../api/api";
import { Container, Row, Col, Button, Card, Form, Spinner } from "react-bootstrap";
import useAuthContext from "../context/AuthContext";
import E1Logo from '../assets/image/e1-logo.jpg';

const Login = () => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error} = useAuthContext();
  
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()
    login({email, password})
    setIsLoading(false)
  };
  
  const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: isHovered ? '#e09e2b' : '#f4b43f',
        width: '100%',
        border: 'none',
        cursor: 'pointer',
        padding: '10px',
        fontSize: '16px',
        color: '#fff',
        transition: 'background-color 0.3s ease',
    };

  return (
    <>
      <Container fluid className="main-login">
        <Row className="center-row">
          <Col lg={7} className="lgcol" style={{backgroundColor:"#1d1833"}}>
            <div className="login-first">
              <h1 className="fw-bold d-flex justify-content-center align-items-center gap-2"><img
                  src={E1Logo}
                  alt="E1 DocEx"
                  width={55}
                  style={{height: 'auto'}}
                /><span>DocEx</span></h1>
              <p>Powered by E1 Consulting</p>
              <h3>Workday Document Management</h3>
            </div>
          </Col>
          <Col lg={5} className="login-form-col" style={{backgroundColor:"#f4b43f"}}>
            <div className="login-form" style={{backgroundColor:"#ffff"}}>
              <div className="login-card">
              
              
                <div className="login-card-header">
                <img
                  src={require("../assets/image/e1docex.png")}
                  alt="E1_Docex-Logo"
                  width={150}
                  style={{height: 'auto'}}
                />
                </div>
                {error && (
                  <div className="text-center text-danger">
                    {error && <small className="error-msg">{error}</small>}
                    {error.email && <small className="error-msg">{error.email[0]}</small>}
                    {error.password && <small className="error-msg">{error.password[0]}</small>}
                  </div>
                )}
                <Form onSubmit={handleSubmit} className="mt-2">
                 
                  <Form.Group className="mb-4" controlId="loginemail">
                    <Form.Label>Email Address</Form.Label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4 pb-4" controlId="loginpassword">
                    <Form.Label>Password</Form.Label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                    />
                  </Form.Group>
                  <Button size="md" type="submit" 
                  style={buttonStyle}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  >
                    Login
                  </Button>
                </Form>
                {isLoading && (
          <div className="loader-container">
            <Spinner animation="border" />
          </div>
        )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;