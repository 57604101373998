import React from 'react';
import './Help.css'; // Import your custom CSS for additional styling

function Help() {
  return (
    <div className="help-container">
      <h3 className="fs-2 mb-4 font-bold">Help</h3>
      <p>If you need assistance or have any questions, please refer to the following resources:</p>
      <ul>
        <li>Check out our <a href="/faq">FAQs</a> for answers to common questions.</li>
        <li>Visit our <a href="/documentation">Documentation</a> for detailed guides and tutorials.</li>
        <li>Contact our support team at <a href="mailto:support@example.com">support@example.com</a>.</li>
      </ul>
    </div>
  );
}

export default Help;
