import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import api from "../../api/api";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";

const AddTenant = () => {
  const navigate = useNavigate();
  const [tenantData, setTenantData] = useState({
    tenant_name: "",
    tenant_url: "",
    tenant_username: "",
    tenant_password: "",
    tenant_alias: "",
    tenant_tag: "",
    staffing_url: "",
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const tenantTagOptions = [
    { value: "soap_call", label: "SOAP Endpoint" },
    { value: "raas_call", label: "RAAS Endpoint" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTenantData({ ...tenantData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSelectChange = (selectedOption) => {
    setTenantData({
      ...tenantData,
      tenant_tag: selectedOption ? selectedOption.value : "",
    });
    setErrors({ ...errors, tenant_tag: "" });
  };

  const validateFields = () => {
    let newErrors = {};
    if (!tenantData.tenant_name) newErrors.tenant_name = "Tenant Name is required";
    if (!tenantData.tenant_url) newErrors.tenant_url = "Tenant URL is required";
    if (!tenantData.tenant_username) newErrors.tenant_username = "Username is required";
    if (!tenantData.tenant_password) newErrors.tenant_password = "Password is required";
    if (!tenantData.tenant_alias) newErrors.tenant_alias = "Alias is required";
    if (!tenantData.tenant_tag) newErrors.tenant_tag = "Tenant Tag is required";
    if (tenantData.tenant_tag === "raas_call" && !tenantData.staffing_url) {
      newErrors.staffing_url = "Staffing URL is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); 
      try {
        const response = await api.post("/tenant/create", tenantData);
        console.log("Tenant added:", response.data);
        Swal.fire("Success!", "Tenant added successfully.", "success").then(
          () => {
            navigate("/tenantmanagement");
          }
        );
      } catch (error) {
        console.error("Error adding tenant:", error);
        Swal.fire("Error!", "There was a problem adding the tenant.", "error");
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <Container className="my-3">
      <Row>
        <Col lg="12">
          <h3 className="fs-2 mb-4 font-bold">Add Tenant</h3>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="6">
                <Form.Group className="mb-3" controlId="tenantName">
                  <Form.Label>Tenant Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter tenant name"
                    name="tenant_name"
                    value={tenantData.tenant_name}
                    onChange={handleChange}
                    maxLength={30}
                  />
                  {errors.tenant_name && (
                    <div className="text-danger">{errors.tenant_name}</div>
                  )}
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group className="mb-3" controlId="tenantURL">
                  <Form.Label>Tenant URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter tenant URL"
                    name="tenant_url"
                    value={tenantData.tenant_url}
                    onChange={handleChange}
                    maxLength={250}
                  />
                  {errors.tenant_url && (
                    <div className="text-danger">{errors.tenant_url}</div>
                  )}
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group className="mb-3" controlId="tenantUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    name="tenant_username"
                    value={tenantData.tenant_username}
                    onChange={handleChange}
                    maxLength={30}
                  />
                  {errors.tenant_username && (
                    <div className="text-danger">{errors.tenant_username}</div>
                  )}
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3 position-relative" controlId="tenantPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    name="tenant_password"
                    value={tenantData.tenant_password}
                    onChange={handleChange}
                    maxLength={30}
                  />
                  <div
                    className="position-absolute top-50 end-0 translate-middle-y mt-3 me-2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </div>
                  {errors.tenant_password && (
                    <div className="text-danger">{errors.tenant_password}</div>
                  )}
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3" controlId="tenantAlias">
                  <Form.Label>Alias</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter alias"
                    name="tenant_alias"
                    value={tenantData.tenant_alias}
                    maxLength={30}
                    onChange={handleChange}
                  />
                  {errors.tenant_alias && (
                    <div className="text-danger">{errors.tenant_alias}</div>
                  )}
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3" controlId="tenantTag">
                  <Form.Label>Tenant Tag</Form.Label>
                  <Select
                    options={tenantTagOptions}
                    value={tenantTagOptions.find(
                      (option) => option.value === tenantData.tenant_tag
                    )}
                    onChange={handleSelectChange}
                    placeholder="Select tenant tag"
                  />
                  {errors.tenant_tag && (
                    <div className="text-danger">{errors.tenant_tag}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {tenantData.tenant_tag === "raas_call" && (
              <Form.Group className="mb-3" controlId="staffingURL">
                <Form.Label>Staffing URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter staffing URL"
                  name="staffing_url"
                  value={tenantData.staffing_url}
                  onChange={handleChange}
                  maxLength={100}
                />
                {errors.staffing_url && (
                  <div className="text-danger">{errors.staffing_url}</div>
                )}
              </Form.Group>
            )}
            <Button
              variant="primary"
              type="submit"
              className="add-user"
              disabled={loading} 
            >
              {loading ? (
                <Spinner animation="border" size="sm" /> 
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddTenant;
