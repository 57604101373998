import { createContext, useContext, useEffect, useState } from "react";
import api from "../api/api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const navigate = useNavigate();

  const getUser = async () => {
    try {
      const { data } = await api.get("/user-values");
      console.log(data);
      setUser(data);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleAuthError = (error) => {
    const statusCode = error.response?.status;

    if (statusCode === 401 || statusCode === 500) {
      setIsLoggedIn(false);
      localStorage.removeItem("access_token");
      setError('Your Session has been Expired');
      navigate("/login");
    } else {
      setError('Invalid Credentials Email/Password is Incorrect.');
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      setIsLoggedIn(true);
      getUser();
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, []);

  const login = async (data) => {
    try {
      const response = await api.post("/login", data);
      const accessToken = response.data.hashed_token;
      
      if (accessToken) {
        localStorage.setItem("access_token", accessToken);
        setIsLoggedIn(true);
        getUser();
        navigate("/");
      } else {
        setError("Invalid Response From The Server");
        setIsLoggedIn(false);
      }
    } catch (e) {
      handleAuthError(e);
    }
  };

  const logout = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/logout');
      if (response.data.status === 200) {
        localStorage.removeItem("access_token");
        setIsLoggedIn(false);
        setUser(null);
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, error, isLoggedIn, getUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
