import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import "./styles.css";
import { MdOutlineGroups3 } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GoDownload } from "react-icons/go";
import { BsUpload } from "react-icons/bs";
import { BsFiletypeDocx } from "react-icons/bs";
import { MdHelpOutline } from "react-icons/md";
import {FaBars} from 'react-icons/fa';
import E1DocXLogo from "../assets/image/e1docex.png";
import { PiSidebarSimpleLight } from "react-icons/pi";
import { PiSidebarSimpleFill } from "react-icons/pi";

function Sidebars({ collapse, toggled, backDrop }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(collapse);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <div className="sidebar-container">
        <Sidebar
          collapsed={isCollapsed}
          transitionDuration={500}
          breakPoint="sm"
          toggled={toggled}
          onBackdropClick={backDrop}
          className="main-sidebar-content"
        >
          <div
            className="mt-3 me-2 ms-2 heading-align"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          >
            <img
              src={E1DocXLogo}
              alt="E1_Docex-Logo"
              width={170}
              style={{ height: "auto" }}
            />
          </div>
          <hr />
          <div className="sidebar-header" onClick={handleCollapseClick} style={{}}>
            {isCollapsed ? <PiSidebarSimpleFill color="#f2a922" style={{fontSize: '24px', marginTop: '-20px', marginLeft: '10px'}} />: <PiSidebarSimpleFill color="#ffffff" style={{fontSize: '24px', marginTop: '-20px', marginLeft: '10px'}}/>}
          </div>
          <Menu>
            <MenuItem
              component={
                <Link
                  to="/"
                  className={
                    location.pathname === "/" ? "menu-item active" : "menu-item"
                  }
                />
              }
            >
              <MdOutlineGroups3 className="icon-side" /> Dashboard
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/tenantmanagement"
                  className={
                    location.pathname === "/tenantmanagement"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <MdOutlineGroups3 className="icon-side" /> Tenant Management
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/usermanagement"
                  className={
                    location.pathname === "/usermanagement"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <AiOutlineUsergroupAdd className="icon-side" /> User Management
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/extractdocments"
                  className={
                    location.pathname === "/extractdocments"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <GoDownload className="icon-side" /> Extract Documents
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/uploaddocument"
                  className={
                    location.pathname === "/uploaddocument"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <BsUpload className="icon-side" /> Upload Documents
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/monitor"
                  className={
                    location.pathname === "/monitor"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <BsFiletypeDocx className="icon-side" /> Monitor
            </MenuItem>
            <MenuItem
              component={
                <Link
                  to="/help"
                  className={
                    location.pathname === "/help"
                      ? "menu-item active"
                      : "menu-item"
                  }
                />
              }
            >
              <MdHelpOutline className="icon-side" /> Help
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
      <div></div>
    </div>
  );
}

export default Sidebars;
