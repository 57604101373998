import './App.css';
import React, {useEffect, useState} from 'react';
import Sidebars from './Components/Sidebar';
import Navbars from './Components/Navbars';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Tenant from './Pages/Tenant'
import ExtractDocs from './Pages/extractDocuments'
import Monitor from './Pages/Monitor'
import Help from './Pages/Help'
import UploadDocument from './Pages/uploadDocument'
import UserManagement from './Pages/userManagement'
import Upload from './Components/Upload';
import Login from './Components/Login';
import Dashboard from './Pages/Dashboard';
import useAuthContext from './context/AuthContext';
import AddTenant from './Components/TenantManagement/AddTenant';
import EditTenant from './Components/TenantManagement/EditTenant';
import AddUser from './Components/UserManagement/AddUser';
import EditUser from './Components/UserManagement/EditUser';


function App() {
  const {user, getUser, isLoggedIn} = useAuthContext();
  const [collapse, setCollapse] = useState(false);
  const [toggled, setToggled] = useState(false);

  const collapseSidebar = () => {
    setCollapse(!collapse);
  };
  
  const handleBackdropClick = () => {
    setToggled(false);
  };
  
  if(isLoggedIn) {
    return (
      <>
        <div className="App">
          
            <Sidebars collapse={collapse}  toggled={toggled} backDrop={handleBackdropClick} /> 
          
            <div className="main-content">
            <Navbars collapseSidebar={collapseSidebar}/>
            <main style={{ padding: '2rem' }}>
              {/* Add your admin dashboard content here */}
              <Routes>
              <Route exact path='/' element={<Dashboard />} />
              <Route exact path='/tenantmanagement' element={<Tenant />} />
              <Route exact path='/extractdocments' element={<ExtractDocs/>} />
              <Route exact path='/monitor' element={<Monitor />} />
              <Route exact path='/help' element={<Help />} />
              <Route exact path='/uploaddocument' element={<UploadDocument />} />
              <Route exact path='/usermanagement' element={<UserManagement />} />
              <Route exact path='/upload' element={<Upload />} />
              <Route exact path='/login' element={<Navigate to = '/' />} />
              <Route exact path='/add-tenant' element={<AddTenant />} />
              <Route exact path='/edit-tenant/:TenantId' element={<EditTenant />} />
              <Route exact path='/add-user' element={<AddUser />} />
              <Route exact path='/edit-user/:UserId' element={<EditUser />} />
            </Routes>
            </main>
          </div>
        </div>
      </>
    );
  }

  return <Login />
  
}

export default App;
