import React from "react";
import { Navbar, Nav,NavDropdown, Image, Card } from "react-bootstrap";
import {FaBars} from 'react-icons/fa'
import {BsGear} from 'react-icons/bs'
import {IoMdNotificationsOutline} from 'react-icons/io'
import useAuthContext from "../context/AuthContext";

const Navbars = ({collapseSidebar}) => {

  const {logout} = useAuthContext();

  return (
    <Card className="navbar-card">
    <Navbar variant="light" expand="lg" className="navbar-dcd"> 
      <Navbar.Brand href="#home" className='nav-heading' >
      
      
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ms-auto me-2" variant="light">

          <NavDropdown className="profile-drop"
            title={
              <span>
                <Image
                  src={require("../assets/image/avatar.png")}
                  alt="User Avatar"
                  roundedCircle
                  width={30}
                  height={30}
                  className="me-3 avatar-image"
                />
                <span className="profile-icon">
                <BsGear className="svg-icon" />
                </span>
                
              </span>
            }
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={logout} className="logout-item">
      Logout
    </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </Card>
  );
};

export default Navbars;
